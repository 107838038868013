<template>
  <div class="Page Home">
    <canvas ref="canvas"></canvas>
    <World v-if="ready" ref="world" />

    <transition name="Home__search">
      <div class="Home__search" v-if="!found">
        <div class="Home__search-text">{{ trans('search.line1') }}</div>
        <div class="Home__search-img" />
        <div class="Home__search-text">{{ trans('search.line2') }}</div>
      </div>
    </transition>

    <transition name="Home__splash">
      <div class="Home__splash" v-if="splash">
        <div class="Home__splash-name" v-html="trans('name')" />
        <div class="Home__splash-title" v-html="trans('title')" />
        <div class="Home__splash-desc" v-html="trans('desc')" />
        <div class="Btn" @click="splash = false">{{ trans('btn') }}</div>
      </div>
    </transition>

    <div class="Home__test">
      <div
        :key="season"
        v-if="!splash"
        :class="{ 'is-active': $route.params.season == season }"
        v-for="season in ['winter', 'spring', 'summer', 'automn']"
        @click="$router.push({ params: { season: season } })">
        {{ season }}
      </div>
    </div>
  </div>
</template>

<script>
import Page from '@/core/mixins/Page'
import World from '@/components/world/World'
import * as THREEAR from 'threear'
import { WebGLRenderer, Scene, Camera, Group, Color, Mesh } from 'three'

export default {
  name: 'Home',
  mixins: [Page],
  components: { World },
  data: () => ({
    raf: null,
    splash: true,
    found: false,
    ready: false
  }),
  translations: {
    fr: {
      search: {
        line1: 'repère le marqueur avec la silhouette de daniel bélanger',
        line2: "en faisant jouer le vinyle, les saisons s'animeront",
      },
      name: 'Daniel Bélanger',
      title: 'quatre saisons<br> dans le désordre',
      desc: 'une expérience unique sur la face d du vinyle rêver mieux.',
      btn: 'débuter'
    }
  },
  mounted: async function() {
    const renderer	= new WebGLRenderer({
      alpha: true,
      antialias: true,
      canvas: this.$refs.canvas,
      preserveDrawingBuffer: true
    })
    const scene = new Scene()
    const camera = new Camera()
    const masterGroup = new Group()
    const ARGroup = new Group()

    ARGroup.add(masterGroup)
    scene.add(ARGroup)

		renderer.setSize(window.innerWidth,window.innerHeight)
    renderer.setClearColor(new Color('lightgrey'), 0)
    renderer.setPixelRatio(window.devicePixelRatio)
		renderer.gammaInput = true
		renderer.gammaOutput = true
		renderer.shadowMap.enabled = true
		renderer.sortObjects = true
		renderer.autoClear = true

    const source = new THREEAR.Source({ renderer, camera })

    try {
      const controller = await THREEAR.initialize({ source: source, patternRatio: .9 })

      const patternMarker = new THREEAR.PatternMarker({
        patternUrl: require('./img/pattern-silhouette.patt'),
        markerObject: ARGroup
      })

      controller.trackMarker(patternMarker)
      controller.addEventListener('markerFound', event => this.found = true)
      controller.addEventListener('markerLost', event => this.found = false)

      this.$masterGroup = masterGroup
      this.$scene = scene
      this.$camera = camera
      this.$renderer = renderer
      this.$controller = controller
      this.$source = source

      TweenMax.set(this.$masterGroup, { three: { scale: .02 } })

      this.animate()

      this.$parent.webcamReady = true
      this.ready = true
    } catch(e) {
      this.$parent.webcamReady = false
    }
  },
  methods: {
    animate() {
			this.raf = requestAnimationFrame(this.animate)
			if(this.$controller)
        this.$controller.update(this.$source.domElement)
      if(this.$refs.world)
        this.$refs.world.animate()

       this.$renderer.render(this.$scene, this.$camera)
    }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'
@import '~@/commons/stylus/Media-queries'

.Home
  height 100vh
  width 100vw
  overflow hidden

  &__search
    display flex
    flex-direction column
    text-transform uppercase
    text-align center
    justify-content center
    height 100vh
    width 100vw
    position fixed
    top 0
    left 0
    align-items center
    transition opacity 1s, transform 1s
    &-enter
    &-leave-active
      opacity 0
      transform scale(.8)
    &-img
      width 50vw
      height 50vw
      background-image url('./img/marker_search.png')
      background-repeat no-repeat
      background-position  center center
      background-size contain
    &-text
      margin 15vw 0
      padding 0 10vw
      font-size 3.5vw
      line-height 6.5vw

  &__splash
    top 0
    left 0
    display flex
    flex-direction column
    text-transform uppercase
    text-align center
    justify-content center
    height 100vh
    width 100vw
    background black
    position fixed
    transition opacity 1s, transform 1s
    &-enter
    &-leave-active
      opacity 0
      transform scale(.8)
    &-name
      color #fbb900
      font-size 4vw
      letter-spacing 2.7vw
    &-title
      color #e84e0e
      margin .5vw 0 5vw
      line-height 7vw
      letter-spacing 1vw
    &-desc
      font-size 3vw
      letter-spacing .5vw
      line-height 5vw
      padding 0 15vw
    .Btn
      width 30vw
      margin 10vw auto 0

  &__test
    position fixed
    top 0
    display flex
    justify-content space-between
    background black
    width 100vw
    div
      color white
      padding 10px
      &.is-active
        background white
        color black

</style>
