<script>

import Season from '@/mixins/Season'
import { Vector3, CatmullRomCurve3 } from 'three'

export default {

  name: 'Summer',
  mixins: [ Season ],
  props: ['group', 'indice'],
  render: h => h(),

  beforeDestroy() {},
  created() {

    this.$elements = []
    this.$array = [
  
      { id: 'leaf_2', x: -0.5, y: 12.8, z: -4, width: 51, height: 51, target: 'arbre_2', indice: 'leaf' },
      { id: 'leaf_1', x: 0, y: 7, z: -4, width: 51, height: 51, target: 'arbre_1', indice: 'leaf' },
      { id: 'leaf_3', x: 0, y: 14, z: -4, width: 51, height: 51, target: 'arbre_3', indice: 'leaf' },
      { id: 'leaf_5', x: 1, y: 8, z: -4, width: 51, height: 51, target: 'arbre_5', indice: 'leaf' },
      { id: 'leaf_4', x: 0, y: 10, z: -4, width: 51, height: 51, target: 'arbre_4', indice: 'leaf' },

      { id: 'ground', x: 0, y: 0, z: 0, width: 120, height: 120, target: 'ground', indice: 'ground' },

      // { id: 'grasse_1', x: -4, y: -2, z: 5, width: 51, height: 51, target: 'arbre_2', indice: 'grasse' },
      // { id: 'grasse_2', x: -10, y: -7, z: 1, width: 51, height: 25, target: 'arbre_3', indice: 'grasse' },
      // { id: 'grasse_21', x: 20, y: -10, z: 5, width: 51, height: 25, target: 'arbre_5', indice: 'grasse' },

      // { id: 'grasse_3', x: -15, y: 12, z: -5, width: 51, height: 51, target: 'stage', indice: 'grasse' },
      // { id: 'grasse_4', x: 8, y: -20, z: 2, width: 25, height: 25, target: 'arbre_1', indice: 'grasse' },
      // { id: 'grasse_5', x: 5, y: 3, z: -30, width: 102, height: 25, target: 'stage', indice: 'grasse' },
      // { id: 'grasse_6', x: 25, y: 4, z: -20, width: 51, height: 51, target: 'stage', indice: 'grasse' },

      // { id: 'grasse_7', x: 0, y: 4, z: -15, width: 51, height: 25, target: 'summer', indice: 'grasse' },
      // { id: 'grasse_8', x: 18, y: 4, z: 20, width: 102, height: 25, target: 'summer', indice: 'grasse' },

      // { id: 'fog', x: 0, y: 30, z: -5, width: 102, height: 102, target: 'fog' }, 

      { id: 'bird_1', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'stage', indice: 'bird' },
      { id: 'bird_2', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'stage', indice: 'bird' },
      { id: 'bird_3', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'stage', indice: 'bird' },
      { id: 'bird_4', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'stage', indice: 'bird' },

    ]

    var curve1 = new CatmullRomCurve3([

      new Vector3(-58, 50, 28 ),
      new Vector3(-51, 44, 39 ),
      new Vector3(-35, 42, 43 ),
      new Vector3(-17, 40, 43 ),
      new Vector3(4, 38, 40 ),
      new Vector3(23, 36, 32 ),
      new Vector3(37, 36, 15 ),
      new Vector3(43, 36, -6 ),
      new Vector3(44, 36, -24 ),
      new Vector3(38, 38, -38 ),
      new Vector3(22, 40, -47 ),
      new Vector3(-8, 42, -49 ),
      new Vector3(-29, 46, -45 ),
      new Vector3(-39, 50, -36 ),
      new Vector3(-38, 50, -30 ),

      // new Vector3(-34, 48, 4 ),
      // new Vector3(-25, 46, 14 ),
      // new Vector3(-13, 42, 21 ),
      // new Vector3(9, 38, 20 ),
      // new Vector3(21, 34, 5 ),
      // new Vector3(22, 30, -16 ),
      // new Vector3(11, 32, -25 ),
      // new Vector3(-3, 32, -30 ),
      // new Vector3(-23, 32, -35 ),
      // new Vector3(-44, 34, -46 ),
      // new Vector3(-56, 34, -53 )

    ])

    var curve2 = new CatmullRomCurve3([

      // new Vector3(0, 35, 36 ),
      // new Vector3(10, 36, 33 ),
      // new Vector3(16, 37, 27 ),
      // new Vector3(19, 38, 20 ),
      // new Vector3(21, 38, 8 ),
      // new Vector3(21, 39, -5 ),
      // new Vector3(16, 40, -16 ),
      // new Vector3(8, 41, -21 ),
      // new Vector3(-6, 42, -22 ),
      // new Vector3(-20, 43, -19 ),
      // new Vector3(-30, 44, -6 ),
      // new Vector3(-32, 45, 7 ),
      // new Vector3(-30, 46, 20 ),
      // new Vector3(-28, 47, 30 ),
      // new Vector3(-21, 48, 35 ),
      // new Vector3(-11, 49, 39 ),
      // new Vector3(2, 50, 40 ),

      // new Vector3(11, 51, 39 ),
      // new Vector3(19, 52, 37 ),
      // new Vector3(29, 53, 33 ),
      // new Vector3(37, 54, 26 ),
      // new Vector3(41, 55, 12 ),
      // new Vector3(41, 56, -4 ),
      new Vector3(35, 40, -16 ),
      new Vector3(26, 40, -28 ),
      new Vector3(12, 40, -34 ),
      new Vector3(-9, 40, -41 ),
      new Vector3(-24, 40, -44 ),
      new Vector3(-43, 40, -45 ),

    ])

    var curve3 = new CatmullRomCurve3([

      // new Vector3(-57, 110, -34 ),
      // new Vector3(-53, 91, -34 ),
      // new Vector3(-50, 78, -34 ),
      // new Vector3(-46, 64, -33 ),
      // new Vector3(-43, 52, -33 ),
      // new Vector3(-39, 43, -32 ),
      // new Vector3(-36, 41, -31 ),
      // new Vector3(-33, 41, -30 ),
      // new Vector3(-29, 44, -28 ),
      // new Vector3(-26, 48, -27 ),
      // new Vector3(-23, 53, -26 ),
      // new Vector3(-21, 58, -25 ),
      // new Vector3(-18, 65, -23 ),
      // new Vector3(-16, 73, -21 ),
      // new Vector3(-12, 80, -20 ),
      // new Vector3(-9, 91, -18 ),
      // new Vector3(-7, 98, -17 ),
      // new Vector3(-4, 104, -16 ),
      // new Vector3(-1, 108, -15 ),
      // new Vector3(1, 110, -15 ),
      // new Vector3(5, 110, -14 ),
      // new Vector3(9, 107, -14 ),
      // new Vector3(12, 100, -14 ),
      // new Vector3(15, 92, -14 ),
      // new Vector3(18, 83, -15 ),
      // new Vector3(21, 74, -16 ),
      // new Vector3(23, 67, -17 ),
      // new Vector3(27, 61, -20 ),
      // new Vector3(30, 53, -21 ),
      // new Vector3(34, 47, -23 ),
      // new Vector3(36, 40, -25 ),
      // new Vector3(38, 34, -26 ),
      // new Vector3(40, 29, -28 ),
      // new Vector3(41, 25, -30 ),
      // new Vector3(43, 22, -31 ),
      // new Vector3(46, 21, -33 ),
      // new Vector3(48, 20, -34 ),
      // new Vector3(51, 19, -35 ),

      // new Vector3(-52, 70, -29 ),
      new Vector3(-45, 60, -3 ),
      new Vector3(-21, 50, 19 ),
      new Vector3(18, 40, 21 ),
      new Vector3(37, 30, 9 ),

      // new Vector3(42, 50, -11 ),
      // new Vector3(39, 54, -34 ),
      // new Vector3(26, 58, -47 ),
      // new Vector3(5, 62, -55 ),
      // new Vector3(-17, 64, -57 ),

      // new Vector3(-57, 20, 55 ),
      // new Vector3(-39, 22, 52 ),
      // new Vector3(-21, 24, 47 ),
      // new Vector3(-8, 26, 40 ),
      // new Vector3(5, 28, 32 ),
      // new Vector3(15, 30, 24 ),
      // new Vector3(27, 33, 13 ),
      // new Vector3(32, 36, 0 ),
      // new Vector3(31, 39, -10 ),
      // new Vector3(26, 42, -23 ),
      // new Vector3(18, 45, -32 ),
      // new Vector3(11, 50, -36 ),
      // new Vector3(-5, 55, -38 ),
      // new Vector3(-24, 56, -38 ),
      // new Vector3(-36, 57, -31 ),
      // new Vector3(-44, 60, -21 ),
      // new Vector3(-47, 62, -4 ),
      // new Vector3(-40, 64, 11 ),
      // new Vector3(-27, 64, 24 ),
      // new Vector3(-10, 70, 32 ),
      // new Vector3(6, 70, 40 ),
      // new Vector3(26, 70, 47 )   

    ])

    curve1.type = 'catmullrom'
    curve1.tension = .05

    curve2.type = 'catmullrom'
    curve2.tension = .05

    curve3.type = 'catmullrom'
    curve3.tension = 1

    this.$points = []

    this.$points.push(curve1.getPoints(135))
    this.$points.push(curve2.getPoints(80))
    this.$points.push(curve3.getPoints(100))

  },

  mounted() {},

  methods: {

    resetWhirlpool() {

      _.each(this.$array_summer_bird, (plane, i)=>{
        this.getWhirlpool(plane, i)
      })
    },

    getWhirlpool(plane, i) {

      const pt = _.random(0, 2)

      plane.points = this.$points[pt]
      plane.cue = 0

      const delay = _.random(1, 4)*i
      this.tweenPlane(plane, delay)
    },

    tweenPlane (plane, delay) {

      plane.position.set(plane.points[plane.cue].x, plane.points[plane.cue].y, plane.points[plane.cue].z) 
      TweenMax.delayedCall(delay, ()=>{  

        plane.cue = (plane.cue < plane.points.length-1) ? plane.cue +=1 : 0
        var look = (plane.cue < plane.points.length-2) ? plane.cue+1 : 0
        plane.lookAt(new Vector3( plane.points[look].x, 0 , plane.points[look].z )) 

        if(plane.cue < 20) plane.material.opacity += 0.05
        else if(plane.cue > plane.points.length-20) plane.material.opacity -= 0.05
        else if(!this.used) plane.material.opacity -= 0.01
   
        if(plane.material.opacity <= 0 && !this.used) plane.material.opacity = 0
        else if(plane.cue < plane.points.length) this.tweenPlane(plane, 0.01) 
        else this.getWhirlpool(plane, 1)

      })
    },



    addMotion() {

      this.used = true
      this.transition_used = false
      this.$tween = new TimelineMax({ delay: 1 }) 
          .add([
            // TweenMax.to(this['$'+ this.indice + '_fog'], 1, { three: { alpha: 1 }, ease: Linear.easeNone, delay: 1 }),
            TweenMax.to(this['$'+ this.indice + '_ground'], 1, { three: { alpha: 1 }, ease: Linear.easeNone }),
            TweenMax.staggerTo(this.$array_summer_leaf, 1, { three: { alpha: 1 }, ease: Linear.easeNone }, .25),

            TweenMax.staggerTo(this.$parent.$with_leafs, 1, { three: { alpha: .75 }, ease: Linear.easeNone }, .25),
            TweenMax.staggerTo(this.$parent.$without_leafs, 1, { three: { alpha: 0 }, ease: Linear.easeNone }, .25),
            
          ])
          .staggerTo(_.shuffle(this.$array_summer_grasse), 1, { three: { alpha: 1 }, ease: Linear.easeNone }, .5)

      TweenMax.delayedCall(3.5, ()=>{ this.resetWhirlpool() })  
    },

    removeMotion() {
      
      this.used = false
      this.transition_used = true

      const tempo = (this.$route.params.season === 'winter' || this.$route.params.season === 'spring') ? .1 : .5
      this.$tween.pause()
      this.$tween = new TimelineMax({ onComplete: ()=>{ this.transition_used = false } })  
          // .staggerTo(_.shuffle(this.$array_summer_grasse), 1, { three: { alpha: 0 }, ease: Linear.easeNone }, tempo)
          // .to(this['$'+ this.indice + '_fog'], 1, { three: { alpha: 0 }, ease: Linear.easeNone })
          .add([
            TweenMax.to(this['$'+ this.indice + '_ground'], 1, { three: { alpha: 0 }, ease: Linear.easeNone }),
            TweenMax.staggerTo(this.$array_summer_leaf,  1, { three: { alpha: 0 }, ease: Linear.easeNone }, tempo),

            // TweenMax.staggerTo(this.$parent.$with_leafs,  1, { three: { alpha: 0 }, ease: Linear.easeNone }, tempo),



          ])      
    },

    update() {

      _.each(this.$array_summer_grasse, (el)=>{ el.lookAt(this.$parent.$camera.position) })

    },

  },
  watch: {},

}
</script>






























