<script>

import { CylinderGeometry, Mesh, MeshLambertMaterial, Group, TextureLoader, MeshPhongMaterial, DoubleSide } from 'three'

export default {

  name: 'Grounds',
  props: ['group'],

  render: h => h(),
  beforeDestroy() {},
  mounted() {

    const geometry = new CylinderGeometry(65, 65, 1, 640)
    const material = new MeshLambertMaterial({ color: 0x262a1b })

    // var textureLoader = new TextureLoader()
    // var texture = textureLoader.load(require('@/components/world/assets/summer/ground.png'))
    // var mat = new MeshPhongMaterial({ map: texture, transparent: true, side: DoubleSide, opacity: 1 })

    const cylinder = new Mesh(geometry, material)
          cylinder.material.depthTest = true
          cylinder.castShadow = true
          cylinder.receiveShadow = true

          cylinder.position.set(0, 0, 0)

    const group = new Group()
          group.add(cylinder)

    this.group.add(group)
    // this.$parent['$ground'] = group

  },

  methods: {}

}
</script>














