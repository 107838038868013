<script>

import { MeshBasicMaterial, Mesh, MeshLambertMaterial, PlaneGeometry, DoubleSide, TextureLoader, Group, Object3D } from 'three'

export default {

    name: 'Threes',
    props: ['group'],

    data: () => ({

        initialized: false,
        tempo: 2.5
    }),

    render: h => h(), 
    beforeDestroy() {},
    created() {

        this.$array = []
        this.$parent.$with_leafs = []
        this.$parent.$without_leafs = []

        this.$elements = []
        this.$threes = [

            { id: 'arbre_2', x: -46, y: 13, z: 17, width: 51, height: 51 },
            { id: 'arbre_1', x: -35, y: 25, z: -22, width: 51, height: 51 },
            { id: 'arbre_3', x: -22, y: 16, z: 40, width: 51, height: 51 },
            { id: 'arbre_5', x: 22, y: 19, z: -5, width: 51, height: 51 },
            { id: 'arbre_4', x: 40, y: 19.5, z: 28, width: 51, height: 51 },

        ]

        this.$shadows = [ 

            { id: 'arbre_1_ombre', target: 'arbre_1', x: 14, y: -26, z: 0, width: 51, height: 51 },
            { id: 'arbre_2_ombre', target: 'arbre_2', x: 24, y: -17.5, z: 0, width: 51, height: 51 },
            { id: 'arbre_3_ombre', target: 'arbre_3', x: 19.5, y: -17.5, z: 0, width: 40, height: 40 },
            { id: 'arbre_4_ombre', target: 'arbre_4', x: 13, y: -21.5, z: 0, width: 40, height: 40 },
            { id: 'arbre_5_ombre', target: 'arbre_5', x: 13, y: -21.5, z: 0, width: 40, height: 40 },
        ]

    },

    methods: {

        Initialize() {

            this.$nextTick(()=>{

                
                this.addElements()
                this.addShadows()
                this.initialized = true
            })    
        },

        addShadowMotion(season) {

            return

            this.$tween.pause()
            const pos = { winter : .1, spring: .21, summer: .38, automn: 1 }

            if(season) TweenMax.to(this.$tween, this.tempo, { progress: pos[season], ease: Linear.easeNone })
            else TweenMax.to(this.$tween, this.tempo, { progress: -40, ease: Cubic.easeOut })

        },

        addShadows() {

            _.each(this.$shadows, (sh)=>{

                // var opacity = (sh.indice === 'without_leafs') ? .75 : 0
                var textureLoader = new TextureLoader()
                var texture = textureLoader.load(require('./assets/global/'+ sh.id +'.png'))
                var geo = new PlaneGeometry( sh.width, sh.height, 32 )
                var mat = new MeshBasicMaterial({ map: texture, transparent: true, side: DoubleSide, opacity: 1 }) 
                var plane = new Mesh(geo, mat)
                    plane.position.set(sh.x, sh.y, sh.z)
                    plane.material.depthTest = false
                    plane.renderOrder = 2

                    //plane.rotation.x = 90 * Math.PI / 180

                    plane.rotation.z = -25 * Math.PI / 180
                
                this.$parent['$'+ sh.target].add(plane)
                this.$array.push(plane)

                // if(sh.indice === 'with_leafs') this.$parent.$with_leafs.push(plane)
                // else this.$parent.$without_leafs.push(plane)

            })

            var obj = { x: -40 }
            this.$tween = new TimelineMax({
                    onUpdate: ()=>{ 

                        TweenMax.set(this.$array, { three: { rotationZ: obj.x } })
                    }
                })
                .to(obj, 10, { 
                    bezier: { 
                        type: "soft", 
                        values:[ 

                            { x: -30 }, 
                            { x: -20 }, 
                            { x: 0 }, 
                            { x: 20 } 
                        ] 
                    }, 
                })
                .stop()

            this.addShadowMotion(this.$route.params.season)
        },

        addElements() {

            _.each(this.$threes, (three)=>{

                var textureLoader = new TextureLoader()
                var texture = textureLoader.load(require('./assets/global/'+ three.id +'.png'))
                var geo = new PlaneGeometry( three.width, three.height, 32 )
                var mat = new MeshBasicMaterial({ map: texture, transparent: true, side: DoubleSide }) 
                var plane = new Mesh(geo, mat)
                    plane.position.set(0, 0, 0)
                    plane.material.depthTest = false
                    plane.renderOrder = 3

                var group = new Group()
                    group.position.set(three.x, three.y, three.z)
                    group.add(plane)

                this.group.add(group)
                this.$parent['$'+ three.id] = group
                this.$elements.push(group)
            })
        },

        update() {

            _.each(this.$elements, (el)=>{
                el.lookAt(this.$parent.$camera.position)
            })

        },

    },

    watch: {

        '$route': {
            immediate: true,
            handler() {

                if(!this.initialized) this.Initialize()
                else this.addShadowMotion(this.$route.params.season)
            }
        },

    },


}
</script>














