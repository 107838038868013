<script>

import Season from '@/mixins/Season'
import { Group, SpriteMaterial, TextureLoader, PlaneGeometry, MeshPhongMaterial, Mesh, DoubleSide } from 'three'
import Proton from 'three.proton.js'

export default {

  name: 'Summer',
  mixins: [ Season ],
  props: ['group', 'indice'],
  render: h => h(),

  beforeDestroy() {},
  created() {

    this.$elements = []
    this.$array = [

      { id: 'leaf_2', x: -0.5, y: 12.8, z: -4, width: 51, height: 51, target: 'arbre_2', indice: 'leaf' },
      { id: 'leaf_1', x: 0, y: 7, z: -4, width: 51, height: 51, target: 'arbre_1', indice: 'leaf' },
      { id: 'leaf_3', x: 0, y: 14, z: -4, width: 51, height: 51, target: 'arbre_3', indice: 'leaf' },
      { id: 'leaf_5', x: 1, y: 8, z: -4, width: 51, height: 51, target: 'arbre_5', indice: 'leaf' },
      { id: 'leaf_4', x: 0, y: 10, z: -4, width: 51, height: 51, target: 'arbre_4', indice: 'leaf' },

      { id: 'ground', x: 0, y: 0, z: 0, width: 120, height: 120, target: 'ground', indice: 'ground' },

      { id: 'lf1', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'automn', indice: 'motion' },
      { id: 'lf2', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'automn', indice: 'motion' },
      { id: 'lf3', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'automn', indice: 'motion' },
      { id: 'lf4', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'automn', indice: 'motion' },
      { id: 'lf5', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'automn', indice: 'motion' },
      { id: 'lf6', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'automn', indice: 'motion' },
      { id: 'lf7', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'automn', indice: 'motion' },
      { id: 'lf8', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'automn', indice: 'motion' },

      // { id: 'fog', x: 0, y: 30, z: -5, width: 102, height: 102, target: 'fog' },

    ]

  },

  mounted() {

    this.createParticules()
  },

  methods: {

    createParticules() {
        const proton = new Proton()
              proton.addEmitter(this.createEmitter())
              proton.addRender(new Proton.SpriteRender(this.$parent.$fog))
        this.$proton = proton
    },

    createEmitter() {

      var textureLoader = new TextureLoader()
      var texture = textureLoader.load(require('@/components/world/assets/automn/water.png'))
      var geo = new PlaneGeometry( 2, 12, 32 )
      var mat = new MeshPhongMaterial({ map: texture, transparent: true, side: DoubleSide, opacity: 0 })
      var plane = new Mesh(geo, mat)
          plane.material.depthTest = false

      this.$sprite = plane

      var position = new Proton.Position()
          position.addZone(new Proton.BoxZone(90, 20, 90))

      const emitter = new Proton.Emitter()
            emitter.rate = new Proton.Rate(new Proton.Span(1, 20), new Proton.Span(.2, ))

            emitter.addInitialize(new Proton.Mass(0.2))
            emitter.addInitialize(new Proton.Radius(new Proton.Span(1)))
            emitter.addInitialize(position)
            emitter.addInitialize(new Proton.Life(0.6))
            emitter.addInitialize(new Proton.Body(this.$sprite))
            // emitter.addBehaviour(new Proton.Alpha(1, 0.1))
            emitter.addBehaviour(new Proton.Gravity(0.5))

            emitter.p.x = 0
            emitter.p.y = 55
            emitter.emit()

      this.$emitter = emitter
      return emitter
    },


    resetWhirlpool() {

      _.each(this.$array_automn_motion, (plane, i)=>{
        this.getWhirlpool(plane, i)
      })
    },

    getWhirlpool(plane, i) {

      const speed = _.random(5, 12)
      const size = _.random(15, 50)

      if(plane.whirlpool)
        plane.whirlpool.pause()

      plane.obj = { x: 0, y: 0, z: 0 }
      plane.whirlpool = new TimelineMax({
        // delay: _.random(2, 5)*i,
        delay: _.random(2, 5),
        onStart: ()=>{
          if(!this.used) plane.whirlpool.pause()
          else {
            TweenMax.set(plane, { three:{ scale: _.random(.25, .65) }})
            TweenMax.to(plane, 0.25, { three:{ alpha: 1 }})
          }
        },
        onUpdate: ()=>{
          plane.position.set(plane.obj.x, plane.obj.y, plane.obj.z)
        },
        onComplete: ()=>{
          TweenMax.set(plane,{ three: { rotationX : 0, rotationY: 0 }})
          if(this.used)
            this.getWhirlpool(plane, 1)
        }

      })

      plane.whirlpool.add([
        TweenMax.to(plane.obj, speed, {
          bezier: {
          type: "soft",
          values:[
            { x: 0, y: 10, z: -size },
            { x: size, y: 20, z: 0 },
            { x: 0, y:_.random(20, 35) , z: size },
            { x: -size, y: 40, z: 0 },
            { x: 0, y: 50, z: -size },
            { x: size, y: 60, z: 0 },
            { x: 0, y: 80, z: size },
            { x: _.random(-30, 30), y: _.random(60, 90), z: _.random(-10, 10) },
          ]},
          ease: Cubic.easeOut
        }),
        TweenMax.to(plane, speed, { three: { rotationX : _.random(90, 360)*3, rotationY: _.random(20, 90) }}),
        TweenMax.to(plane, 1, { three: { alpha : 0}, ease: Linear.easeNone, delay: speed*.65 })
      ])
    },

    addMotion() {

      this.used = true
      this.transition_used = false
      this.$tween = new TimelineMax({ delay: 1 })
          .add([

            // TweenMax.to(this['$'+ this.indice + '_fog'], 1, { three: { alpha: 1 }, ease: Linear.easeNone, delay: 1 }),
            TweenMax.to(this['$'+ this.indice + '_ground'], 1, { three: { alpha: 1 }, ease: Linear.easeNone }),
            TweenMax.staggerTo(this.$array_automn_leaf, 1, { three: { alpha: 1 }, ease: Linear.easeNone }, .25),
            TweenMax.to(this.$sprite, 1, { three: { alpha: 1 }, ease: Linear.easeNone , delay: 4 }),
            TweenMax.staggerTo(this.$parent.$with_leafs, 1, { three: { alpha: .75 }, ease: Linear.easeNone }),
            TweenMax.staggerTo(this.$parent.$without_leafs, 1, { three: { alpha: 0 }, ease: Linear.easeNone }),
          ])

      TweenMax.delayedCall(3.5, ()=>{ this.resetWhirlpool() })
    },

    removeMotion() {

      this.used = false
      this.transition_used = true
      this.$tween.pause()
      this.$tween = new TimelineMax({ onComplete: ()=>{ this.transition_used = false } })
          .set({}, { delay: 1.5 })
          .add([

            // TweenMax.to(this['$'+ this.indice + '_fog'], .5, { three: { alpha: 0 }, ease: Linear.easeNone }),
            TweenMax.to(this['$'+ this.indice + '_ground'], 1, { three: { alpha: 0 }, ease: Linear.easeNone }),
            TweenMax.staggerTo(this.$array_automn_leaf,  1, { three: { alpha: 0 }, ease: Linear.easeNone }, .5),
            TweenMax.to(this.$sprite, 1, { three: { alpha: 0 }, ease: Linear.easeNone , delay: .25 }),

          ])
    },

    update() {

      if(this.$route.params.season != this.indice && !this.transition_used)
        return

      if(this.$proton)
        this.$proton.update()

    },

  },
  watch: {},

}
</script>














