<template>
  <div class="IschromeIOS">
    <div class="IschromeIOS__text" v-html="trans('nocam')"/>
  </div>
</template>

<script>

export default {
  name: 'chromeIOS',
  translations: {
    en: {
      nocam: `
        Oh! Please use safari!<br><br>
        This experience uses the cameras of your phone,
        which works using Safari.
      `,
    },
    fr: {
      nocam: `
        Oh! Utilisez plutôt Safari!<br><br>
        Cette experience utilise les caméras de votre téléphone,
        fonctionnant seulement avec Safari.
      `,
    },
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.IschromeIOS
  position fixed
  top 0
  left 0
  z-index 999999
  height 100%
  width 100%
  overflow hidden
  text-align center
  text-transform uppercase
  display flex
  justify-content center
  flex-direction column
  background black

  &__text
    padding 0 10vw
    font-size 5vw
    line-height 8vw

</style>
