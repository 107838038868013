import Vue from 'vue'
import Vuex from 'vuex'

import App from './modules/App'
import Loader from './modules/Loader'
import Data from './modules/Data'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app: App,
    loader: Loader,
    data: Data
  }
})
