<template>
<div id="App">
  <transition
    @enter="enter" @after-enter="afterEnter"
    @leave="leave" @after-leave="afterLeave"
    @before-enter="beforeEnter"
    :css="false" appear :mode="mode">
    <router-view ref="page" />
  </transition>

  <Nowebcam v-if="webcamReady === false && !IschromeIOS" />
  <chromeIOS v-if="IschromeIOS" />
  <Desktop v-if="isDesktop" />

  <Loader v-if="!loaded"/>
</div>
</template>

<script>
import Loader from '@/components/loader/Loader'
import Transition from '@/core/mixins/Transition'
import Nowebcam from '@/components/nowebcam/Nowebcam'
import chromeIOS from '@/components/ischromeios/IschromeIOS'
import Desktop from '@/components/desktop/Desktop'

export default {
  name: 'App',
  mixins: [ Transition ],
  components: { Loader, Nowebcam, chromeIOS, Desktop },
  data: () => ({
    webcamReady: null,
    IschromeIOS: false,
    isDesktop: true
  }),
  computed: {
    loaded() {
      return this.$store.getters['loader/loaded']
    }
  },
  created: async function() {
    this.isDesktop = !navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
    if(this.isDesktop) return

    const userAgent = navigator.userAgent.toLowerCase()
    const ios = /iphone|ipod|ipad/.test(userAgent)

    if(ios && navigator.userAgent.match('CriOS'))
      return this.IschromeIOS = true
  }
}
</script>

<style lang="stylus">
@import '~reset-css/reset.css'
@import '~@/config/Settings'
@import '~@/commons/fonts/Fonts'
@import '~@/commons/stylus/Global'
@import '~@/commons/stylus/Page'
@import '~@/commons/stylus/Vlazy'
</style>
