<script>

import Season from '@/mixins/Season'
import { Group, SpriteMaterial, TextureLoader, Sprite } from 'three'

export default {

  name: 'Summer',
  mixins: [ Season ],
  props: ['group', 'indice'],
  render: h => h(),

  beforeDestroy() {},
  created() {

    this.$elements = []
    this.$array = [

      // { id: 'fog', x: 0, y: 30, z: -5, width: 102, height: 102, target: 'fog' }, 
      // { id: 'ground', x: 0, y: 0, z: 0, width: 120, height: 120, target: 'scene', indice: 'ground' },
      
      { id: 'ground', x: 0, y: 0, z: 0, width: 120, height: 120, target: 'ground', indice: 'ground' },

      { id: 'lf1', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'spring', indice: 'motion' },
      { id: 'lf2', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'spring', indice: 'motion' },
      { id: 'lf3', x: 0, y: 75, z: 0, width: 12, height: 12, target: 'spring', indice: 'motion' },
      { id: 'lf4', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'spring', indice: 'motion' },
      { id: 'lf5', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'spring', indice: 'motion' },
      { id: 'lf6', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'spring', indice: 'motion' },
      { id: 'lf7', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'spring', indice: 'motion' },
      { id: 'lf8', x: 0, y: 75, z: 0, width: 25, height: 25, target: 'spring', indice: 'motion' },

    ]

    const leafs = [

      { target: 'arbre_2', x: 0.5, y: 1, z: 0 },
      { target: 'arbre_2', x: 5, y: 4, z: 0 },
      { target: 'arbre_2', x: 1, y: 6, z: 0 },
      { target: 'arbre_2', x: -7, y: 15, z: 0 },
      { target: 'arbre_2', x: -13, y: 11, z: 0 },
      { target: 'arbre_2', x: -10.5, y: 3, z: 0 },
      { target: 'arbre_2', x: -3.5, y: 12, z: 0 },
      { target: 'arbre_2', x: -8, y: 6.5, z: 0 },
      { target: 'arbre_2', x: 3, y:13, z: 0 },
      { target: 'arbre_2', x: 10, y:8, z: 0 },
      { target: 'arbre_2', x: 8.6, y:2.2, z: 0 },

      { target: 'arbre_1', x: 0.9, y: 18, z: 0 },
      { target: 'arbre_1', x: 2.4, y: 11, z: 0 },
      { target: 'arbre_1', x: 5.2, y: 10, z: 0 },
      { target: 'arbre_1', x: -0.5, y: 8, z: 0 },
      { target: 'arbre_1', x: -5, y: 6, z: 0 },
      { target: 'arbre_1', x: -1, y: 0, z: 0 },
      { target: 'arbre_1', x: 3, y: -2, z: 0 },
      { target: 'arbre_1', x: -4.5, y: -2, z: 0 },
      { target: 'arbre_1', x: 8.7, y: -1.5, z: 0 },
      { target: 'arbre_1', x: 8.4, y: 4.2, z: 0 },
      { target: 'arbre_1', x: 5.4, y: 5, z: 0 },

      { target: 'arbre_3', x: 0.8, y: 19, z: 0 },
      { target: 'arbre_3', x: -5.4, y: 18.2, z: 0 },
      { target: 'arbre_3', x: -4.8, y: 16, z: 0 },
      { target: 'arbre_3', x: -7.5, y: 14.7, z: 0 },
      { target: 'arbre_3', x: -14.7, y: 15.7, z: 0 },
      { target: 'arbre_3', x: 2.4, y: 12, z: 0 },
      { target: 'arbre_3', x: 8, y: 16.2, z: 0 },
      { target: 'arbre_3', x: 6, y: 18, z: 0 },
      { target: 'arbre_3', x: 12, y: 16.5, z: 0 },
      { target: 'arbre_3', x: 10, y: 18.5, z: 0 },
      { target: 'arbre_3', x: 14, y: 14.5, z: 0 },
      { target: 'arbre_3', x: 10, y: 6.5, z: 0 },
      { target: 'arbre_3', x: 9, y: 4.5, z: 0 },
      { target: 'arbre_3', x: 3, y: 9, z: 0 },

      { target: 'arbre_5', x: 0.8, y: 19, z: 0 },
      { target: 'arbre_5', x: 2.8, y: 15, z: 0 },
      { target: 'arbre_5', x: -2.8, y: 11, z: 0 },
      { target: 'arbre_5', x: 2, y: 9, z: 0 },
      { target: 'arbre_5', x: 5.5, y: 7.5, z: 0 },
      { target: 'arbre_5', x: 5.5, y: 1.5, z: 0 },
      { target: 'arbre_5', x: 3.5, y: 3.8, z: 0 },
      { target: 'arbre_5', x: -.75, y: 4.5, z: 0 },
      { target: 'arbre_5', x: -3.5, y: 1, z: 0 },
      { target: 'arbre_5', x: -6.5, y: 3, z: 0 },
      { target: 'arbre_5', x: -4, y: 5, z: 0 },
      { target: 'arbre_5', x: -1, y: -4, z: 0 },
      { target: 'arbre_5', x: 3, y: -5, z: 0 },

      { target: 'arbre_4', x: 0.8, y: 18, z: 0 },
      { target: 'arbre_4', x: -5.4, y: 19, z: 0 },
      { target: 'arbre_4', x: -4.8, y: 14, z: 0 },
      { target: 'arbre_4', x: -8.5, y: 18, z: 0 },
      { target: 'arbre_4', x: -11, y: 15.7, z: 0 },
      { target: 'arbre_4', x: 2.4, y: 12, z: 0 },
      { target: 'arbre_4', x: 9, y: 16, z: 0 },
      { target: 'arbre_4', x: 3, y: 16, z: 0 },
      { target: 'arbre_4', x: 12.5, y: 16.5, z: 0 },
      { target: 'arbre_4', x: 7.5, y: 18.5, z: 0 },
      { target: 'arbre_4', x: 13.5, y: 14, z: 0 },
      { target: 'arbre_4', x: 10.7, y: 7, z: 0 },
      { target: 'arbre_4', x: 8.5, y: 3.5, z: 0 },
      { target: 'arbre_4', x: 3.5, y: 9, z: 0 },
      { target: 'arbre_4', x: -1.2, y: 2.8, z: 0 },
      { target: 'arbre_4', x: -4.75, y: 2, z: 0 },
      { target: 'arbre_4', x: -7, y: 4, z: 0 },
      { target: 'arbre_4', x: -9.5, y: 2, z: 0 },
      { target: 'arbre_4', x: -12, y: 8.5, z: 0 },
      { target: 'arbre_4', x: -13, y: 6, z: 0 },
      { target: 'arbre_4', x: 1.5, y: -1, z: 0 },
    ]

    const img = ['leaf_1', 'leaf_2']

    _.each(leafs, (leaf, indice)=>{
    
      this.$array.push({ 
        id: img[_.random(0, 1)],
        x: leaf.x, 
        y: leaf.y, 
        z: leaf.z, 
        width: 7, 
        height: 7, 
        target: leaf.target, 
        indice: 'leaf',
        scale: _.random(.5, .75)
      })

    })

  },

  mounted() {
    //
  },

  methods: {

    resetWhirlpool() {

      _.each(this.$array_spring_motion, (plane, i)=>{
        this.getWhirlpool(plane, i)
      })
    },

    getWhirlpool(plane, i) {

      const speed = _.random(8, 20)
      const size = _.random(15, 50)

      if(plane.whirlpool) plane.whirlpool.pause()
      
      plane.pos = { x: _.random(-30, 30), y: 0, z:  _.random(-30, 30) }
      plane.obj = plane.pos
      plane.whirlpool = new TimelineMax({ 
        
        // delay: _.random(3.5, 10)*i, 
        delay: _.random(2,7), 

        onStart: ()=>{ 
          if(!this.used) plane.whirlpool.pause()
          else { 
            TweenMax.set(plane, { three:{ scale: _.random(.25, .65) }}) 
            TweenMax.to(plane, 0.25, { three:{ alpha: 1 }}) 
          }
        },
        onUpdate: ()=>{ 
          plane.position.set(plane.obj.x, plane.obj.y, plane.obj.z) 
        },
        onComplete: ()=>{ 
          TweenMax.set(plane,{ three: { rotationX : 0, rotationY: 0 }})
          if(this.used)
            this.getWhirlpool(plane, 1) 
        }

      })

      plane.whirlpool.add([

        TweenMax.to(plane.obj, speed, { 
          bezier: { 
          type: "soft", 
          values:[ 
            
            { x: plane.pos.x, y: _.random(5, 15), z: plane.pos.z-size }, 
            { x: plane.pos.x+size, y: _.random(10, 20), z: plane.pos.z }, 
            { x: plane.pos.x, y: _.random(30, 50), z: plane.pos.z+size },  
            { x: plane.pos.x, y: _.random(30, 50), z: plane.pos.z-size },  
            { x: plane.pos.x+_.random(-30, 30), y: _.random(10, 50), z: plane.pos.z+_.random(-10, 10) }, 

          ]}, 
          ease: Cubic.easeOut
        }),

        TweenMax.to(plane, speed, { three: { rotationX : _.random(90, 360)*3, rotationY: _.random(20, 90) }}),
        TweenMax.to(plane, 1, { three: { alpha : 0}, ease: Linear.easeNone, delay: speed*.65 })
      ])

    },

    addMotion() {

      this.used = true
      this.transition_used = false
      this.$tween = new TimelineMax({ delay: 1 })
         .add([

            TweenMax.staggerTo(this.$parent.$with_leafs, 1, { three: { alpha: 0 }, ease: Linear.easeNone }),
            TweenMax.staggerTo(this.$parent.$without_leafs, 1, { three: { alpha: .75 }, ease: Linear.easeNone }),
          ])
          .to(this['$'+ this.indice + '_ground'], 1, { three: { alpha: 1 }, ease: Linear.easeNone })
          // .to(this['$'+ this.indice + '_fog'], 1, { three: { alpha: 1 }, ease: Linear.easeNone })
          .add([
            TweenMax.staggerTo(_.shuffle(this.$array_spring_leaf), 1, { three: { alpha: 1 }, ease: Linear.easeNone }, 0.2),
          ])

      TweenMax.delayedCall(3.5, ()=>{ this.resetWhirlpool() })  
    },

    removeMotion() {
      
      this.used = false
      this.transition_used = true
      this.$tween.pause()
      this.$tween = new TimelineMax({ onComplete: ()=>{ this.transition_used = false } })  
          // .to(this['$'+ this.indice + '_fog'], 1, { three: { alpha: 0 }, ease: Linear.easeNone })
          .add([

            TweenMax.to(this['$'+ this.indice + '_ground'], 1, { three: { alpha: 0 }, ease: Linear.easeNone }),
            TweenMax.staggerTo(_.shuffle(this.$array_spring_leaf), .25, { three: { alpha: 0 }, ease: Linear.easeNone }, 0.02),
          ])     
    },

    update() {

      if(this.$route.params.season != this.indice && !this.transition_used)
        return
    },

  },
  watch: {},

}
</script>


































