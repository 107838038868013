export default {
  fr: {
    Global: {
    }
  },
  en: {
    Global: {
    }
  }
}
