<template>
<div class="World">
  <Ground :group="$ground"/>
  <Lights :group="$scene"/>
  <Threes :group="$stage" ref="threes"/>
  <Sun :group="$stage" ref="sun"/>
  <Buddy  :group="$stage" ref="buddy"/>

  <Winter :group="$stage" indice="winter" ref="winter"/>
  <Spring :group="$stage" indice="spring" ref="spring"/>
  <Summer :group="$stage" indice="summer" ref="summer"/>
  <Automn :group="$stage" indice="automn" ref="automn"/>
</div>
</template>

<script>
import { Group, Fog } from 'three'

import Ground from '@/components/world/Ground'
import Lights from '@/components/world/Lights'
import Threes from '@/components/world/Threes'
import Sun from '@/components/world/Sun'
import Buddy  from '@/components/world/Buddy'

import Winter from '@/components/world/Winter'
import Spring from '@/components/world/Spring'
import Summer from '@/components/world/Summer'
import Automn from '@/components/world/Automn'

export default {
  name: 'World',
  components: { Ground, Lights, Threes, Buddy, Winter, Spring, Summer, Automn, Sun },
  created() {
    this.$scene = this.$parent.$scene
    this.$camera = this.$parent.$camera

    this.$stage = new Group()
    this.$ground = new Group()
    this.$fog = new Group()
    this.$winter = new Group()
    this.$spring = new Group()
    this.$summer = new Group()
    this.$automn = new Group()

    this.$parent.$masterGroup.add(this.$fog, this.$ground, this.$winter, this.$spring, this.$summer, this.$automn, this.$stage)
  },
  methods: {
    animate() {
      const toUpdate = ['buddy', 'sun', 'threes', 'winter', 'spring', 'summer', 'automn']
      const toLookAt = ['$winter', '$spring', '$summer', '$automn', '$fog']
      _.each(toUpdate, name => {
        if(this.$refs[name])
          this.$refs[name].update()
      })
      _.each(toLookAt, name =>{
        if(this[name])
          this[name].lookAt(this.$camera.position)
      })
    }
  }
}
</script>
