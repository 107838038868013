
import { TextureLoader, PlaneGeometry, MeshPhongMaterial, Mesh, DoubleSide, Object3D } from 'three'

export default {

  data: () => ({

    initialized: false,
    transition_used: false,
    used: false

  }),

  methods: {

    addMotion() {
      //
    },

    removeMotion() {
      //
    },

    update() {
      //
    },

    Initialize() {

      this.$nextTick(()=>{

        this.addElements()
        this.initialized = true

        if(this.indice === this.$route.params.season)
          this.addMotion()

      })
    },

    addElements() {

      this.$elements = []
      this.$lookats = []

      // console.log(this.indice, this.$route.params.season);

      const opacity = (this.indice === this.$route.params.season) ? 1 : 0

      _.each(this.$array, (el)=>{

        const name = (el.duplicate) ? el.duplicate : el.id

        var textureLoader = new TextureLoader()
        var texture = textureLoader.load(require('@/components/world/assets/'+ this.indice +'/'+ name +'.png'))
        var geo = new PlaneGeometry( el.width, el.height, 32 )
        var mat = new MeshPhongMaterial({ map: texture, transparent: true, side: DoubleSide, opacity: 0 })
        var plane = new Mesh(geo, mat)
            plane.position.set(el.x, el.y, el.z)
            plane.material.depthTest = false
            plane.renderOrder = (el.indice === 'ground') ? 0 : 3

        if(el.scale) plane.scale.set(el.scale, el.scale, el.scale)
        if(el.indice === 'ground') plane.rotation.x = 90 * Math.PI / 180
        if(el.rotationZ) plane.rotation.z = el.rotationZ * Math.PI / 180

        // if(el.opacity && this.indice === this.$route.params.season) mat.opacity = el.opacity
        // if(el.target === this.indice) this.$lookats.push(plane)

        this.$elements.push(plane)
        this.$parent['$'+ el.target].add(plane)
        this['$'+ this.indice + '_'+ el.id] = plane

        if(el.indice)
          this.keepByIndice(el.indice, plane)

      })

    },

    keepByIndice(indice, plane) {

      if(!this['$array_'+ this.indice +'_'+ indice]) this['$array_'+ this.indice +'_'+ indice] = []
      this['$array_'+ this.indice +'_'+ indice].push(plane)
    },

  },

  watch: {

    '$route': {
      immediate: true,
      handler() {

        if(!this.initialized) this.Initialize()
        else if(this.indice === this.$route.params.season) {

          this.used = true
          this.addMotion()
        } 
        else if(this.used) {

          this.used = false
          this.removeMotion()
        }

      }
    },

  },

}
