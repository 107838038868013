<script>

import Season from '@/mixins/Season'
import { Group, SpriteMaterial, TextureLoader, Sprite } from 'three'
import Proton from 'three.proton.js'

export default {

  name: 'Summer',
  mixins: [ Season ],
  props: ['group', 'indice'],
  render: h => h(),

  beforeDestroy() {},
  created() {

    this.$parent.$clouds = new Group()
    this.$parent.$winter.add(this.$parent.$clouds)

    this.$tha = 0

    this.$array = [

      { id: 'clouds_1', x: -25, y: 70, z: -50, width: 25, height: 25, target: 'clouds', indice: 'clouds' },
      { id: 'clouds_2', x: -38, y: 45, z: 0, width: 51, height: 51, target: 'clouds', indice: 'clouds' },
      { id: 'clouds_3', x: -40, y: 35, z: 20, width: 25, height: 25, target: 'clouds', indice: 'clouds' },
      { id: 'clouds_4', x: 20, y: 60, z: 45, width: 51, height: 51, target: 'clouds', indice: 'clouds' },
      { id: 'clouds_5', x: 45, y: 50, z: 0, width: 51, height: 51, target: 'clouds', indice: 'clouds' },
      { id: 'clouds_6', x: 60, y: 30, z: 25, width: 25, height: 25, target: 'clouds', indice: 'clouds' },
      { id: 'fog', x: 0, y: 30, z: -5, width: 102, height: 102, target: 'fog' },
      { id: 'ground', x: 0, y: 0, z: 0, width: 120, height: 120, target: 'ground', indice: 'ground' },
      { id: 'ground_1', x: 0, y: 0.5, z: 0, width: 98, height: 98, target: 'ground', indice: 'ground' },
      { id: 'ground_2', x: 0, y: 1.0, z: 0, width: 98, height: 98, target: 'ground', indice: 'ground' },
      { id: 'ground_3', x: 0, y: 1.5, z: 0, width: 108, height: 108, target: 'ground', indice: 'ground' },
      { id: 'ground_4', x: 0, y: 2.0, z: 0, width: 105, height: 105, target: 'ground', indice: 'ground' },
    ]

  },

  mounted() {

    this.createParticules()
  },

  methods: {

    createParticules() {
        const proton = new Proton()
              proton.addEmitter(this.createEmitter())
              proton.addRender(new Proton.SpriteRender(this.$parent.$fog))
        this.$proton = proton
    },

    createEmitter() {

      const material = new SpriteMaterial({
        map: new TextureLoader().load(require('@/components/world/assets/winter/snow.png')),
        transparent: true,
        fog: true,
        opacity: 0
      })

      this.$sprite = new Sprite(material)

      var position = new Proton.Position()
          position.addZone(new Proton.BoxZone(60, 5, 20))

      const emitter = new Proton.Emitter()
            emitter.rate = new Proton.Rate(new Proton.Span(1, 20), new Proton.Span(.2, 2))

            emitter.addInitialize(new Proton.Mass(0.5))
            emitter.addInitialize(new Proton.Radius(new Proton.Span(0.2, 1.5)))
            emitter.addInitialize(position)
            emitter.addInitialize(new Proton.Life(2, 4))
            emitter.addInitialize(new Proton.Body(this.$sprite))
            emitter.addInitialize(new Proton.Velocity(0, new Proton.Vector3D(0, 0, 0), 90))

            emitter.addBehaviour(new Proton.RandomDrift(1, 0, 0, 0))
            emitter.addBehaviour(new Proton.Rotate("random", "random"))
            emitter.addBehaviour(new Proton.Gravity(.05))

            emitter.p.x = 0
            emitter.p.y = 50
            emitter.emit()

      this.$emitter = emitter
      return emitter
    },

    addMotion() {

      this.used = true
      this.transition_used = false

      this.$tween_clouds = this.$array_winter_clouds
      this.$tween_grounds = [ this.$winter_ground_1, this.$winter_ground_2, this.$winter_ground_3, this.$winter_ground_4 ]

      this.$tween = new TimelineMax({ delay: 1 })
          .to(this.$winter_ground, 1, { three: { alpha: .25 }, ease: Linear.easeNone })
          .add([

            TweenMax.staggerTo(this.$parent.$with_leafs, 1, { three: { alpha: 0 }, ease: Linear.easeNone }),
            TweenMax.staggerTo(this.$parent.$without_leafs, 1, { three: { alpha: .75 }, ease: Linear.easeNone }),

          ])
          .to(this.$winter_fog, 1, { three: { alpha: 1 }, ease: Linear.easeNone })
          .add([

            TweenMax.staggerTo(this.$tween_grounds, 1, { three: { alpha: .25 }, ease: Linear.easeNone }, 4),
            TweenMax.staggerTo(this.$tween_clouds, 1, { three: { alpha: 1 }, ease: Linear.easeNone }, 1),
            TweenMax.to(this.$sprite, 1, { three: { alpha: 1 }, ease: Linear.easeNone , delay: 4 }),

          ])
    },

    removeMotion() {

      this.used = false
      this.transition_used = true

      this.$tween.pause()
      this.$tween = new TimelineMax({ onComplete: ()=>{ this.transition_used = false } })
          .add([

            TweenMax.staggerTo(this.$tween_grounds, 1, { three: { alpha: 0 }, ease: Linear.easeNone }, .1),
            TweenMax.staggerTo(this.$tween_clouds, .5, { three: { alpha: 0 }, ease: Linear.easeNone }, .1),
            TweenMax.to(this.$sprite, .25, { three: { alpha: 0 }, ease: Linear.easeNone  }),

          ])
          .to(this.$winter_fog, .5, { three: { alpha: 0 }, ease: Linear.easeNone })
          .to(this.$winter_ground, 1, { three: { alpha: 0 }, ease: Linear.easeNone })
    },

    update() {

      if(this.$route.params.season != this.indice && !this.transition_used)
        return

      if(this.$proton)
        this.$proton.update()

        this.$tha += Math.PI / 10
        var p = 50 * Math.sin(10 * this.$tha)
        this.$emitter.p.x = p * Math.cos(this.$tha)
        this.$emitter.p.y = 50 + p * Math.sin(this.$tha)


      this.$parent.$clouds.rotation.y += 0.001
      _.each(this.$array_winter_clouds, (el)=>{ el.lookAt(this.$parent.$camera.position) })

    },

  },
  watch: {},

}
</script>

















