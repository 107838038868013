import Vue from 'vue'
import Cookies from 'js-cookie'
import DefaultLanguage from '@/core/js/DefaultLanguage'
import Settings from '@/config/Settings'

const route = {
  name: null,
  meta: { 
    lang: DefaultLanguage, 
    name: null
  }
}

export default {
  namespaced: true,
  state: {
    lang: DefaultLanguage,
    mode: Settings.mode,
    route: {
      from: route,
      to: route
    }
  },
  mutations: {
    'SET_MODE' (state, mode) {
      state.mode = mode
    },
    'RESET_MODE' (state) {
      state.mode = Settings.mode
    },
    'SET_FROM_ROUTE' (state, from) {
      state.route.from = from
    },
    'SET_TO_ROUTE' (state, to) {
      state.route.to = to
    },
    'LANG_REFRESH' (state) {
      const l = state.lang
      state.lang = 'none'
      state.lang = l
    },
    'LANG_CHANGED' (state, lang) {
      state.lang = lang
      Cookies.set(Settings.cookies.lang, state.lang)
    },
  },
  getters: {
    lang: state => state.lang,
    mode: state => state.mode,
    route: state => state.route,
  }
}
