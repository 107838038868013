<template>
<div class="Desktop">
  <div class="Desktop__name" v-html="trans('name')" />
  <div class="Desktop__title" v-html="trans('title')" />
  <div class="Desktop__desc" v-html="trans('desc')" />
</div>
</template>

<script>
export default {
  name: 'Desktop',
  translations: {
    fr: {
      name: 'Daniel Bélanger',
      title: 'quatre saisons<br> dans le désordre',
      desc: 'une expérience en réalité augmentée<br>accessible sur mobile ou tablette',
    }
  },
}
</script>

<style lang="stylus">
@import '~@/config/Settings'
@import '~@/commons/stylus/Media-queries'

.Desktop
  display flex
  flex-direction column
  text-transform uppercase
  text-align center
  justify-content center
  height 100vh
  position absolute
  width 100vw
  z-index 9999
  background black
  &__name
    color #fbb900
    font-size 1.5vw
    letter-spacing 1.7vw
  &__title
    color #e84e0e
    margin .5vw 0 2vw
    line-height 2.1vw
    font-size 1.4vw
    letter-spacing 1vw
  &__desc
    font-size 1.1vw
    letter-spacing .5vw
    line-height 2vw

</style>
