import _ from 'lodash'
import * as CommonsTranslations from '@/commons/lang/Index.js'
import Settings from '@/config/Settings'

let locales = {}
_.each(CommonsTranslations, o => _.merge(locales, o))

export default {
  computed: {
    commonsTranslations: {
      get: () => locales,
      set: o => _.merge(locales, o)
    },
  },
  created() {
    this.setLangRouteNames()
  },
  methods: {
    langSwitch(lang) {
      if(lang === undefined) {
        if(Settings.locale.length > 2)
          throw new Error('Please specify the lang')
        lang = _.without(Settings.locale, this.lang)
      }

      this.$router.replace({
        name: this.trans('Route.name', lang),
        params: this.trans('Route.params', lang),
        query: this.trans('Route.query', lang)
      })
    },
    setLangRouteNames() {
      let langRouteNames = {}

      _.each(Settings.locale, lang => {
        langRouteNames[lang] = {
          Route: { name: this.$route.meta.name + '.' + lang, params: null, query: {} }
        }
      })

      this.commonsTranslations = langRouteNames
    }
  }
}
