<script>

import { MeshBasicMaterial, Mesh, PlaneGeometry, DoubleSide, TextureLoader, Object3D } from 'three'

export default {

    name: 'daniel',
    props: ['group'],

    render: h => h(),
    beforeDestroy() {},
    mounted() {

        this.$els = [

            { id: 'silhouette', x: -2, y: 23.2, z: 10, width: 51, height: 51 },
        ]

        this.addElements()
        this.addShadow()

    },

    methods: {


        addElements() {

            _.each(this.$els, (el)=>{

                var textureLoader = new TextureLoader()
                var texture = textureLoader.load(require('@/components/world/assets/global/'+ el.id +'.png'))
                var geo = new PlaneGeometry( el.width, el.height, 32 )
                var mat = new MeshBasicMaterial({ map: texture, transparent: true, side: DoubleSide })
                var plane = new Mesh(geo, mat)
                    plane.position.set(el.x, el.y, el.z)
                    plane.material.depthTest = false
                    plane.renderOrder = 3

                this['$'+ el.id] = plane
                this.group.add(plane)

            })

        },

        addShadow() {

            var pivot = new Object3D()
                pivot.rotation.x = 90 * Math.PI / 180
                // pivot.rotation.z = 330 * Math.PI / 180
                pivot.position.set(-4, 1.2, 16)

            this.group.add( pivot )

            var textureLoader = new TextureLoader()
            var texture = textureLoader.load(require('@/components/world/assets/global/ombre.png'))
            var gp = new PlaneGeometry( 50, 20, 32 )
            var mp = new MeshBasicMaterial({ map: texture, transparent: true, side: DoubleSide })
            var plane = new Mesh(gp, mp)
                plane.position.set(22.5, 5.5, 0)
                plane.renderOrder = 2

            pivot.add( plane )


            var obj = { x: -40 }
            this.$tween = new TimelineMax({
                    onUpdate: ()=>{

                        TweenMax.set(this.pivot, { three: { rotationZ: obj.x } })
                    }
                })
                .to(obj, 10, {
                    bezier: {
                        type: "soft",
                        values:[

                            { x: -30 },
                            { x: -20 },
                            { x: 0 },
                            { x: 20 }
                        ]
                    },
                })
                .stop()

        },

        update() {

            this.$silhouette.lookAt(this.$parent.$camera.position)
        },

    }
}
</script>














