<template>
<transition name="Loader" appear>
  <div class="Loader">
    <div class="Loader__spin" />
  </div>
</transition>
</template>

<script>
import Settings from '@/config/Settings'

export default {
  computed: {
    promises() {
      return this.$store.getters['loader/promises']
    }
  },
  created() {
    if(Settings.cms)
      this.loadWordpress()

    Promise.all(this.promises).then(() => this.$store.commit('loader/LOADER_LOADED'))
  },
  methods: {
    loadWordpress() {
      const params = {
        per_page: 500,
        hide_empty: 0,
        orderby: 'menu_order',
        order: 'asc'
      }

      const endpoints = [
        { api: 'pages', option: false },
        { api: 'general', option: true },
      ]

      _.each(endpoints, endpoint => {
        const url = endpoint.option ? `options/${endpoint.api}` : endpoint.api
        this.$store.commit('loader/PROMISE_PUSH', this.$http.get(url, { params: params })
          .then(res => this.$store.commit('data/SET_DATA', { option: endpoint.option, type: endpoint.api, data: res.data }))
        )
      })
    }
  }
}

</script>

<style lang="stylus">
@import '~@/config/Settings'

.Loader
  position fixed
  left  0
  right 0
  bottom 0
  top 0
  background black
  z-index 999999
  transition opacity 1s

  &-enter
  &-leave-active
    opacity 0

  &__spin
    position absolute
    top 50%
    left 50%
    margin -25px 0 0 -25px
    border 2px solid rgba(white, .5)
    border-top 2px solid rgba(black, .1)
    border-radius 50%
    width 50px
    height 50px
    animation Loader__spin 2s linear infinite

    @keyframes Loader__spin {
      from { transform: rotate(0deg) }
      to { transform: rotate(360deg) }
    }
</style>
