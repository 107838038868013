<script>

import { MeshPhongMaterial, Mesh, PlaneGeometry, DoubleSide, TextureLoader, Path, BufferGeometry, LineBasicMaterial, Line } from 'three'

export default {

    name: 'Sun',
    props: ['group'],

    data: () => ({

        initialized: false,
        tempo: 2.5
    }),

    render: h => h(),
    beforeDestroy() {},
    mounted() {

        this.$nextTick(()=>{
            this.Initialize()
            this.initialized = true
        })
    },

    methods: {

        Initialize() {

            this.addSun()
        },

        addMotion() {

            this.$tween.pause()
            const pos = { winter : .1, spring: .21, summer: .38, automn: 1 }

            if(this.$route.params.season) {

                TweenMax.to(this.$tween, this.tempo, { progress: pos[this.$route.params.season], ease: Linear.easeNone })
                TweenLite.to(this.$sun, 2, { three: { alpha: 1 }, ease: Expo.easeOut })

            } else {

                TweenMax.to(this.$tween, this.tempo, { progress: 0, ease: Cubic.easeOut })
                TweenLite.to(this.$sun, 2, { three: { alpha: 0 }, ease: Expo.easeOut })
            }
        },

        addSun() {

            var el = { x: -56, y: 8, z: 0, width: 25, height: 25 }
            var textureLoader = new TextureLoader()
            var texture = textureLoader.load(require('@/components/world/assets/global/sun.png'))
            var geo = new PlaneGeometry( el.width, el.height, 32 )
            var mat = new MeshPhongMaterial({ map: texture, transparent: true, side: DoubleSide, opacity: 0 })
            var plane = new Mesh(geo, mat)
                plane.position.set(el.x, el.y, el.z)
                plane.material.depthTest = false
                plane.renderOrder = 1

            this.group.add(plane)
            this.$sun = plane

            var obj = { x: -56, y: 8 }
            this.$tween = new TimelineMax({
                    onUpdate: ()=>{
                        this.$sun.position.set(obj.x, obj.y, -100)
                    }
                })
                .to(obj, 10, {
                    bezier: {
                        type: "soft",
                        values:[

                            { x: -50, y: 45 },
                            { x: 0, y: 70 },
                            { x: 30, y: 60 },
                            { x: 50, y: 45 }
                        ]
                    },
                    //ease: Cubic.easeOut,
                })
                .stop()

            TweenMax.delayedCall(2, this.addMotion)
        },

        update() {
            this.$sun.lookAt(this.$parent.$camera.position)
        },

    },

    watch: {

        '$route': {
            immediate: true,
            handler() {

                if(!this.initialized) this.Initialize()
                else this.addMotion()
            }
        },

    },

}
</script>














