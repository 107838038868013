<template>
  <div class="Nowebcam">

    <div class="Nowebcam__title" v-html="trans('title')" />
    <div class="Nowebcam__subtitle" v-for="(list, index) in trans('list')">
      <span class="Nowebcam__prefix">{{ index + 1 }}.</span>{{ list }}
    </div>
    <div class="Btn is-border-black" @click="click">{{ trans('ok') }}</div>

  </div>
</template>

<script>
export default {
  name: 'Nowebcam',
  translations: {
    fr: {
      title: 'Activez la caméra dans Safari.',
      list: [
        'Allez dans Réglages.',
        'Sélectionnez Safari.',
        'Activez Accès à la caméra et au micro.',
      ],
      ok: 'OK'
    },
    en: {
      title: 'Allow Safari access to your camera.',
      list: [
        'Go to Settings.',
        'Tap Safari.',
        'Turn on Camera & Microphone Access.',
      ],
      ok: 'OK'
    }
  },
  methods: {
    click() {
      location.reload()
    }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'
@import '~@/commons/stylus/Media-queries'

.Nowebcam
  position fixed
  top 0
  left 0
  z-index 999999
  height 100%
  width 100%
  overflow hidden
  text-align center
  text-transform uppercase
  display flex
  justify-content center
  flex-direction column
  background black

  &__title
    font-size 7vw
    line-height 12vw
    letter-spacing 1vw
    margin-bottom 2vw

  &__subtitle
    width 80vw
    margin 3vw auto
    letter-spacing .5vw
    font-size 3vw
    line-height 6vw

  &__prefix
    margin-right 2vw

  &__prefix
    display inline-block

  .Btn
    margin 5vw auto
    width 10vw

</style>
