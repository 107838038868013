export default {
  namespaced: true,
  state: {
    general: {},
    pages: {}
  },
  mutations: {
    'SET_DATA' (state, res) {

      if(res.type == 'general')
        state.general = res.data.acf

      if(res.type == 'pages')
        _.each(res.data, data => state.pages[data.acf.admin.route_key] = {  ...data.acf, id: data.id  })

    },
  },
  getters: {
    general: state => state.general,
    pages: state => state.pages
  }
}
