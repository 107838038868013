export default {
  author: 'Origin',
  name: 'Origin',
  cookies: {
    lang: 'origin_lang',
  },
  locale: ['fr'],
  loader: true,
  mode: '',
  prefixRoute: false,
  cms: false
}
