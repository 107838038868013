<script>

import { HemisphereLight, HemisphereLightHelper, SphereBufferGeometry, ShaderMaterial, BackSide, Mesh, Color, DirectionalLight, DirectionalLightHelper, PlaneBufferGeometry, MeshLambertMaterial } from 'three'

export default {

  name: 'Lights',
  props: ['group'],

  render: h => h(), 
  beforeDestroy() {},

  mounted() {

    // const sky = 0x82c2f4
    // const ground = 0xade1f0
    // const intensity = 0.05

    // this.$hemisphere = new HemisphereLight(sky, ground, intensity)
    // this.$hemisphere.position.set(0, 200, 0)

    // this.group.add(this.$hemisphere)

    // return


    var hemiLight = new HemisphereLight( 0xffffff, 0xffffff, 0.6 );
        hemiLight.color.setHSL( 0.6, 1, 0.6 );
        hemiLight.groundColor.setHSL( 0.095, 1, 0.75 );
        hemiLight.position.set( 0, 20, 0 );

    this.group.add( hemiLight );

    var hemiLightHelper = new HemisphereLightHelper( hemiLight, 2 );

    this.group.add( hemiLightHelper );


    var dirLight = new DirectionalLight( 0xffffff, 1 );
        dirLight.color.setHSL( 0.1, 1, 0.95 );
        dirLight.position.set( - 1, 1.75, 1 );
        dirLight.position.multiplyScalar( 30 );
    this.group.add( dirLight );
        dirLight.castShadow = true;
        dirLight.shadow.mapSize.width = 2048;
        dirLight.shadow.mapSize.height = 2048;
    var d = 50;
        dirLight.shadow.camera.left = - d;
        dirLight.shadow.camera.right = d;
        dirLight.shadow.camera.top = d;
        dirLight.shadow.camera.bottom = - d;
        dirLight.shadow.camera.far = 3500;
        dirLight.shadow.bias = - 0.0001;

    // var dirLightHeper = new DirectionalLightHelper( dirLight, 10 );
    // this.group.add( dirLightHeper );
        // GROUND
  

    // // SKYDOME
    // var vertexShader = document.getElementById( 'vertexShader' ).textContent
    // var fragmentShader = document.getElementById( 'fragmentShader' ).textContent
    // var uniforms = {
    //   "topColor": { value: new Color( 0x0077ff ) },
    //   "bottomColor": { value: new Color( 0xffffff ) },
    //   "offset": { value: 33 },
    //   "exponent": { value: 0.6 }
    // }
    //   uniforms[ "topColor" ].value.copy( hemiLight.color )

    // // this.$parent.$scene.fog.color.copy( uniforms[ "bottomColor" ].value )

    // var skyGeo = new SphereBufferGeometry( 4000, 32, 15 );
    // var skyMat = new ShaderMaterial( {
    //   uniforms: uniforms,
    //   vertexShader: vertexShader,
    //   fragmentShader: fragmentShader,
    //   side: BackSide
    // } );
    // var sky = new Mesh( skyGeo, skyMat );
    // this.group.add( sky );




    

  },
 
}
</script>














