import _ from 'lodash'
import Vue from 'vue'
import Axios from 'axios'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import VueLazyload from 'vue-lazyload'
import Settings from '@/config/Settings'

import App from '@/App.vue'
import store from '@/vuex/Store'
import { mappingRoutes } from '@/Route.js'
import TranslationMixin from '@/core/mixins/Translation'
import CommonsMixin from '@/mixins/Commons'
import LangMixin from '@/core/mixins/Lang'
import '@/core/js/Copyright'

// import { TweenMax, CSSPlugin } from 'gsap/all'
// // because of three shaking
// const gsapPlugins = [ TweenMax, CSSPlugin ]

import 'gsap-three-plugin'
import { TweenMax, TweenLite, CSSPlugin, TimelineMax, Expo, ScrollToPlugin, BezierPlugin } from 'gsap/all'
const gsapPlugins = [ TweenMax,  TweenLite, CSSPlugin, TimelineMax, Expo, Linear, Cubic, ScrollToPlugin, BezierPlugin ]


const axios = Axios.create({ baseURL: '/wp-json/acf/v3' })
Vue.prototype.$http = axios
Vue.use(VueRouter)
Vue.use(Meta,{
  keyName: 'head'
})
Vue.use(VueLazyload)
Vue.mixin(TranslationMixin)
Vue.mixin(CommonsMixin)

const init = async () => {

  if(process.env.NODE_ENV !== 'production' && Settings.cms) {
    const res = await axios.get('pages', { params: { hide_empty: 0, per_page: 500 }})
    window.__ENV = {}
    window.__ENV['ROUTES'] = _.map(res.data, d => ({
      id: d.id,
      name: d.acf.admin.route_key,
      component: d.acf.admin.component,
      fr: d.acf.admin.fr.slug,
      en: d.acf.admin.en.slug
    }))
  }

   if(!Settings.cms) {
    // hardcoded routes
    window.__ENV = {}
    window.__ENV['ROUTES'] = [
      { id: 'home', name: 'home', component: 'home/Home', fr: 'accueil/:season?', en: 'home/:season?' },
    ]
  }

  const router = new VueRouter({
    mode: 'history',
    routes: mappingRoutes(window.__ENV['ROUTES'])
  })

  new Vue({
    el: '#App',
    store,
    router,
    mixins: [ LangMixin ],
    head() {
      return {
        htmlAttrs: { lang: this.lang }
      }
    },
    data: () => ({
      width: window.innerWidth
    }),
    beforeCreate() {
      this.$store.commit('app/LANG_CHANGED', this.$route.meta.lang)
      this.$store.commit('app/SET_FROM_ROUTE', this.$route)
      this.$store.commit('app/SET_TO_ROUTE', this.$route)
    },
    created() {
      window.addEventListener('resize', this.onResize)
      this.$sizes =  {
        phone: 767,
        tabletPortrait: 768,
        tablet: 1024,
      }
    },
    mounted() {
      this.onResize()
    },
    methods: {
      onResize() { this.width = window.innerWidth },
      below(type) { return this.width <= this.$sizes[type] },
      above(type) { return this.width >= this.$sizes[type] }
    },
    render: h => h(App)
  })
}

init()
